export const URLS = {
    TRAINING_VENUE_TEMPLETE: '/training/venue-templete',
    TRAINING_TIME_TEMPLETE: '/training/time-templete',
    TRAINING_VENUE_MANAGE: '/training/venue-manage',
    TRAINING_DRIVER_REQUEST_LIST: '/training/request-list',
    TRAINING_DRIVER_REQUEST_LIST_TEACHER: '/training/request-list-teacher',
    TRAINING_DRIVER_SCHEDULE: '/training/driver-schedule',
    TRAINING_MANAGE_NEW: '/training/manage-new',

    RECRUIT_LIST: '/recruit/list',

    CHAT_PARTNER_LIST: '/chat/patner/list',
    CHAT_CUSTOMER_LIST: '/chat/customer/list',
    CHAT_PARTNER_DETAIL: '/chat/patner/detail',
    CHAT_CUSTOMER_DETAIL: '/chat/customer/detail',
    CHAT_DRIVING_LIST: '/chat/driving/list',
    CHAT_DRIVING_DETAIL: '/chat/driving/detail',
}