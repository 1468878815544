import classNames from 'classnames';
import React from 'react';
import TextWithNewlines from '../TextWithNewLines';


const Chat = chat => {
    const content = chat.chat;
    const user = (content.userType == 0 || content.userType == 2) ? 0 : 1;   // 1: 고객 / 2: 드라이버(파트너)
    // 0 : 본인, 1 : 타인


    const jsonConvertBody = (jsonStr) => {
        let retData = ''
        try {
            const result = JSON.parse(jsonStr)
            retData = result.Body
        }
        catch (e) {
            retData = '[SYS]오류발생'
        }
        return retData
    }

    const jsonConvertSys = (jsonStr) => {
        let retData = ''
        try {
            const result = JSON.parse(jsonStr)
            retData = result.TypeId
        }
        catch (e) {
            retData = '[SYS]오류발생'
        }
        return retData
    }

    return (
        <div className={classNames('flex gap-8 chat-wrap', `chat-wrap-user-${user}`)}>
            <div className={classNames(`user-${user}`, 'chat')}>
                {(jsonConvertSys(content.message) == '0') ?
                    <span className='txt-regular'>
                        <TextWithNewlines text={jsonConvertBody(content.message)} />
                    </span> :
                    <span className='txt-regular'>[시스템에서 발송한 봇 메시지 입니다.]</span>
                }

            </div>
            <span className='txt-small block time grey-9'>{content.time}</span>
        </div>
    );
};

export default Chat;
