import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BOT, CHATTING } from '../../InitConst';
import Chat from '../../components/chat/Chat';
import { getChatDetail, setChatSend } from '../../lib/RestApi';
import useInterval from '../../hooks/useInterval';

const ChatDrivingDetail = () => {
    const { id } = useParams();
    const chatContainerRef = useRef(null);
    const [chatting, setChatting] = useState(null);
    const [updateFlag, setUpdateFlag] = useState(0);
    const [inputMsg, setInputMsg] = useState('');
    const [firstFlag, setFirstFlag] = useState(true);
    const [customerInfo, setCustomerInfo] = useState({
        Name: '',
        PhoneNumber: '',
    })
    const [chatLength, setChatLength] = useState(0)

    useEffect(() => {
        let searchParams = new URLSearchParams(window.location.search);
        setCustomerInfo({
            Name: searchParams.get('name'),
            PhoneNumber: searchParams.get('phone'),
        })
    }, [])

    useEffect(() => {
        const getData = async () => {
            const result = await getChatDetail(id)
            setChatting(result?.ResultData);
        }

        getData();

        // const chat = CHATTING.find(chatting => chatting.id == id);
        // setChatting(chat);
    }, [id, updateFlag]);

    useInterval(async () => {
        setUpdateFlag(updateFlag + 1)
    }, 3000)


    useEffect(() => {
        if (firstFlag) {
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
            setFirstFlag(false)
        }

    }, [chatting]);

    const updateChat = async () => {
        try {
            const result = await getChatDetail('rooms_' + id)
            setChatting(result?.ResultData);
        }
        catch(e) {

        }
        return true;

    }

    
    

    // // 엔터 및 단축키 처리
    // const handleKeyDown = (event) => {
    //     if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey) {
    //         event.preventDefault(); // 기본 엔터 동작 방지
    //         send(inputMsg)
    //     } else if (event.key === 'Enter' && (event.shiftKey || event.ctrlKey)) {
    //         event.preventDefault();
    //         setInputMsg((prevMessage) => prevMessage + '\n'); // 개행 문자 추가
    //     }
    // };


    if (!chatting) return null;

    return (
        <>

            <div style={{ position: 'fixed', width: '100%' }}>
                <div className={'px-25'} style={{
                    height: '40px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    background: '#eee'
                }}>
                    <span style={{ fontWeight: 'bold' }}>
                        {customerInfo.Name} 파트너 ({customerInfo.PhoneNumber})
                    </span>
                </div>
            </div>

            <div className='chatting-body px-25' ref={chatContainerRef}>
                <span className='txt-small grey-9 date'>{chatting.date}</span>
                {/* {chatting.bot && <Chat chat={BOT} />} */}
                {chatting.map((chat, index) => {
                    return <Chat key={index} chat={chat} />;
                })}
            </div>
            {/* <div style={{ position: 'fixed', bottom: '0', width: '100%', padding: '20px', background:'#fff' }}>
                <div style={{ display: 'flex', alignItems: 'center', }}>
                    <textarea
                        value={inputMsg}
                        onChange={(e) => setInputMsg(e.target.value)}
                        onKeyDown={handleKeyDown}
                        placeholder="메시지를 입력하세요..."
                        rows="3"
                        style={{ flex: 1, resize: 'none' }}
                    />
                    <button onClick={() => send(inputMsg)} style={{ marginLeft: '10px', height: '78px', width: '60px' }}>
                        전송
                    </button>
                </div>
            </div> */}
        </>
    );
};

export default ChatDrivingDetail;
