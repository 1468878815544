// const BASE_API_URL = 'https://apitest.driverapi.mosiler.com'
// const BASE_API_URL = 'http://localhost:3000'
const BASE_API_URL = 'https://testa.mosiler.com'
const BASE_API_TRAINING_URL = '/api/admin/training'
const BASE_API_RECRUIT_URL = '/api/admin/recruit'
const BASE_API_CHAT_URL = '/api/admin/chat'

export const API_URL_CONST = {
    VENUE_TEMPLETE: `${BASE_API_URL}${BASE_API_TRAINING_URL}/venue-templete`,
    TIME_TEMPLETE: `${BASE_API_URL}${BASE_API_TRAINING_URL}/time-templete`,
    AVAILABLE_TEMPLETE: `${BASE_API_URL}${BASE_API_TRAINING_URL}/available-templete`,
    TRAINING_MANAGE: `${BASE_API_URL}${BASE_API_TRAINING_URL}/training-manage`,
    TRAINING_REQUEST: `${BASE_API_URL}${BASE_API_TRAINING_URL}/training-request`,
    TRAINING_REQUEST_DATE: `${BASE_API_URL}${BASE_API_TRAINING_URL}/training-request-date`,
    TRAINER_SCHEDULE: `${BASE_API_URL}${BASE_API_TRAINING_URL}/trainer-schedule`,
    TRAINING_PASS: `${BASE_API_URL}${BASE_API_TRAINING_URL}/pass-process`,
    
    ESTIMATE_USER_LIST: `${BASE_API_URL}${BASE_API_RECRUIT_URL}/user-list`,
    ESTIMATE_USER_DETAIL: `${BASE_API_URL}${BASE_API_RECRUIT_URL}/user-detail`,

    RECRUIT_LIST: `${BASE_API_URL}${BASE_API_RECRUIT_URL}/list`,
    RECRUIT_DETAIL: `${BASE_API_URL}${BASE_API_RECRUIT_URL}/detail`,

    CHAT_LIST: `${BASE_API_URL}${BASE_API_CHAT_URL}/list`,
    CHAT_CUSTOMER_LIST: `${BASE_API_URL}${BASE_API_CHAT_URL}/list-customer`,
    CHAT_DETAIL: `${BASE_API_URL}${BASE_API_CHAT_URL}/detail`,
    CHAT_CUSTOMER_DETAIL: `${BASE_API_URL}${BASE_API_CHAT_URL}/detail-customer`,

    CHAT_DRIVING_LIST: `${BASE_API_URL}${BASE_API_CHAT_URL}/list-driving`,
    CHAT_DRIVING_DETAIL: `${BASE_API_URL}${BASE_API_CHAT_URL}/driving-detail`,
    
    SET_CHAT_SEND_PARTNER: `${BASE_API_URL}${BASE_API_CHAT_URL}/send-partner`,
    SET_CHAT_SEND_CUSTOMER: `${BASE_API_URL}${BASE_API_CHAT_URL}/send-customer`,
    UPDATE_CHAT_READED_PARTNER: `${BASE_API_URL}${BASE_API_CHAT_URL}/readed`,
    UPDATE_CHAT_READED_CUSTOMER: `${BASE_API_URL}${BASE_API_CHAT_URL}/readed-customer`,
    NEW_TRAINING_LIST: `${BASE_API_URL}${BASE_API_TRAINING_URL}/list`,
    NEW_TRAINING_LIST_TEACHER: `${BASE_API_URL}${BASE_API_TRAINING_URL}/list-teacher`,
    SET_NEW_TRAINING: `${BASE_API_URL}${BASE_API_TRAINING_URL}/add`,
    GET_NEW_TRAINING_DETAIL: `${BASE_API_URL}${BASE_API_TRAINING_URL}/detail/`,
    GET_NEW_TRAINING_DETAIL_TEACHER: `${BASE_API_URL}${BASE_API_TRAINING_URL}/detail-teacher`,
    UPDATE_NEW_TRAINING_DETAIL: `${BASE_API_URL}${BASE_API_TRAINING_URL}/update`,

}