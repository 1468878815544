import axios from 'axios';
import { API_URL_CONST } from './apiurl';

export const getVenueTemplete = async () => {
    let retData = {}
    try {
        let url = API_URL_CONST.VENUE_TEMPLETE
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const setVenueTemplete = async (TrainingVenueName, TrainingVenueAddress) => {
    let retData = {}
    try {
        let url = API_URL_CONST.VENUE_TEMPLETE
        let result = await axios.post(url, {
            TrainingVenueName,
            TrainingVenueAddress
        })
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const delVenueTemplete = async (Id) => {
    let retData = {}
    try {
        let url = API_URL_CONST.VENUE_TEMPLETE + `/${Id}`
        let result = await axios.delete(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const getTimeTemplete = async () => {
    let retData = {}
    try {
        let url = API_URL_CONST.TIME_TEMPLETE
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const setTimeTemplete = async (SetList) => {
    let retData = {}
    try {
        let url = API_URL_CONST.TIME_TEMPLETE
        let result = await axios.post(url, {
            SetList
        })
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const delTimeTemplete = async (Id) => {
    let retData = {}
    try {
        let url = API_URL_CONST.TIME_TEMPLETE + `/${Id}`
        let result = await axios.delete(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}


export const getAvailableTemplete = async () => {
    let retData = {}
    try {
        let url = API_URL_CONST.AVAILABLE_TEMPLETE
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const getTrainingManage = async () => {
    let retData = {}
    try {
        let url = API_URL_CONST.TRAINING_MANAGE
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}


export const setTrainingManage = async (obj) => {
    let retData = {}
    try {
        let url = API_URL_CONST.TRAINING_MANAGE
        let result = await axios.post(url, {...obj})
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const delTrainingManage = async (Id) => {
    let retData = {}
    try {
        let url = API_URL_CONST.TRAINING_MANAGE + `/${Id}`
        let result = await axios.delete(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}
export const getTrainingRequestList = async (DateYmd) => {
    let retData = {}
    try {
        let url = API_URL_CONST.TRAINING_REQUEST + `/${DateYmd}`
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}
export const getTrainingRequestMonthList = async (DateYm) => {
    let retData = {}
    try {
        let url = API_URL_CONST.TRAINING_REQUEST_DATE + `/${DateYm}`
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}


export const getTrainerScheduleList = async (DateYm, DriverNo) => {
    let retData = {}
    try {
        let url = API_URL_CONST.TRAINER_SCHEDULE + `/${DateYm}/${DriverNo}`
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        retData = e.response.data
    }
    return retData
    
}


export const updateTrainingPass = async (PassStatus, TrainingRequestId, DriverNo) => {
    let retData = {}
    try {
        let url = API_URL_CONST.TRAINING_PASS
        let result = await axios.put(url, {PassStatus, TrainingRequestId, DriverNo})
        retData = result.data
    }
    catch(e) {
        retData = e.response.data
    }
    return retData
    
}


export const setTrainerSchedule = async (obj) => {
    let retData = {}
    try {
        let url = API_URL_CONST.TRAINER_SCHEDULE
        let result = await axios.post(url, obj)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}


// recruit
export const getEstimateUserList = async (keyword) => {
    let retData = {}
    try {
        let url = API_URL_CONST.ESTIMATE_USER_LIST + `/${keyword}`
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const getEstimateUserDetail = async (keyword) => {
    let retData = {}
    try {
        let url = API_URL_CONST.ESTIMATE_USER_DETAIL + `/${keyword}`
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const getRecruitList = async () => {
    let retData = {}
    try {
        let url = API_URL_CONST.RECRUIT_LIST
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const getRecruitDetail = async (AnnounceId) => {
    let retData = {}
    try {
        let url = API_URL_CONST.RECRUIT_DETAIL + `/${AnnounceId}`
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const getChatList = async (pageNumber, pageSize) => {
    let retData = {}
    try {
        let url = `${API_URL_CONST.CHAT_LIST}/${pageNumber}/${pageSize}`
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const getChatListCustomer = async (PageNumber, PageSize) => {
    let retData = {}
    try {
        let url = `${API_URL_CONST.CHAT_CUSTOMER_LIST}/${PageNumber}/${PageSize}`
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}


export const getChatDetail = async (RoomId) => {
    let retData = {}
    try {
        let url = `${API_URL_CONST.CHAT_DETAIL}/${RoomId}`
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const getChatDetailCustomer = async (RoomId) => {
    let retData = {}
    try {
        let url = `${API_URL_CONST.CHAT_CUSTOMER_DETAIL}/${RoomId}`
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}


export const setChatSend = async (RoomId, AdminNo, Content) => {
    let retData = {}
    try {
        let chatMsg = {
            TypeId: 0,
            Body: Content,
        }
        
        let url = `${API_URL_CONST.SET_CHAT_SEND_PARTNER}`
        let result = await axios.post(url, {
            RoomId, 
            AdminNo, 
            Content: JSON.stringify(chatMsg)
        })
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const setChatSendCustomer = async (CustomerNo, Content) => {
    let retData = {}
    try {
        let chatMsg = {
            TypeId: 0,
            Body: Content,
        }
        
        let url = `${API_URL_CONST.SET_CHAT_SEND_CUSTOMER}`
        let result = await axios.post(url, {
            CustomerNo, 
            Content: JSON.stringify(chatMsg)
        })
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const updateChatReaded = async (RoomId) => {
    let retData = {}
    try {
        let url = `${API_URL_CONST.UPDATE_CHAT_READED_PARTNER}`
        let result = await axios.put(url, {
            RoomId
        })
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}



export const updateChatReadedCustomer = async (RoomId) => {
    let retData = {}
    try {
        let url = `${API_URL_CONST.UPDATE_CHAT_READED_CUSTOMER}`
        let result = await axios.put(url, {
            RoomId
        })
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const getNewTrainingList = async (page, row) => {
    let retData = {}
    try {
        const qs = `?page=${page}&row=${row}`
        let url = `${API_URL_CONST.NEW_TRAINING_LIST}${qs}`
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const setNewTraining = async (StartDate, EndDate, TrainingInfo) => {
    let retData = {}
    try {
        let url = `${API_URL_CONST.SET_NEW_TRAINING}`
        let result = await axios.post(url, {
            StartDate, EndDate, TrainingInfo
        })
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const updateNewTraining = async (info) => {
    let retData = {}
    try {
        let url = `${API_URL_CONST.UPDATE_NEW_TRAINING_DETAIL}`
        let result = await axios.put(url, info)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const getNewTrainingDetail = async (DateYmd) => {
    let retData = {}
    try {
        let url = `${API_URL_CONST.GET_NEW_TRAINING_DETAIL}${DateYmd}`
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}


export const getNewTrainingListTeacher = async (page, row, venue) => {
    let retData = {}
    try {
        const qs = `?page=${page}&row=${row}&venue=${venue}`
        let url = `${API_URL_CONST.NEW_TRAINING_LIST_TEACHER}${qs}`
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const getNewTrainingDetailTeacher = async (DateYmd, Venue) => {
    let retData = {}
    try {
        let url = `${API_URL_CONST.GET_NEW_TRAINING_DETAIL_TEACHER}/${DateYmd}/${Venue}`
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}

export const getChatListDriving = async () => {
    let retData = {}
    try {
        let url = `${API_URL_CONST.CHAT_DRIVING_LIST}`
        let result = await axios.get(url)
        retData = result.data
    }
    catch(e) {
        console.log(e)
        retData = {
            ResultCode: 400,
            ResultMessage: '에러 발생'
        }
    }
    return retData
    
}