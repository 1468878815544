import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Table } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { getTrainingRequestList, getTrainingRequestMonthList, updateTrainingPass } from '../../lib/RestApi';
import Calendar from '../../components/Calendar';
import { isNullOrUndefined } from '../../lib/Util';




const TrainingRequestListViewTeacher = (props) => {
	const [selectedDate, setSelectedDate] = useState(moment().format('yyyy-MM-DD'))
	const [monthDate, setMonthDate] = useState(moment().format('yyyy-MM'))
	const [TrainingReqList, setTrainingReqList] = useState([])
	const [monthList, setMonthList] = useState([])
	const [updateFlag, setUpdateFlag] = useState(0)

	useEffect(() => {
		const getData = async () => {
			let result = await getTrainingRequestList(moment(selectedDate).format('yyyy-MM-DD'))
			console.log(result)
			if (result.ResultCode == 200) {
				setTrainingReqList(result.ResultData)
			}
			else {
				alert('데이터 취득 실패')
			}

			let result2 = await getTrainingRequestMonthList(moment().format('yyyy-MM'))
			if (result2.ResultCode == 200) {
				setMonthList(result2.ResultData)
			}
			else {
				alert('데이터 취득 실패')
			}
		}
		getData();
	}, [])

	useEffect(() => {
		const getData = async () => {
			setTrainingReqList([])
			let format = moment(selectedDate).format('yyyy-MM-DD')
			let result = await getTrainingRequestList(format)
			if (result.ResultCode == 200) {
				setTrainingReqList(result.ResultData)
			}
			else {
				alert('데이터 취득 실패')
			}
		}
		getData();
	}, [selectedDate])

	useEffect(() => {
		const getData = async () => {
			setTrainingReqList([])
			let result2 = await getTrainingRequestMonthList(monthDate)
			if (result2.ResultCode == 200) {
				setMonthList(result2.ResultData)
			}
			else {
				alert('데이터 취득 실패')
			}
		}
		getData();
	}, [monthDate])

	const getStrPassFlag = (flag) => {
		let retData = ''
		switch (Number(flag)) {
			case 0:
				retData = '불합격'
				break;
			case 1:
				retData = '합격'
				break;
			default:
				break;
		}
		return retData
	}

	const handlePassUnpassUpdate = async (e, id, DriverNo) => {
		let str = getStrPassFlag(e.target.value)

		if (str.length >= 1) {
			if (window.confirm(`${id} ${str}처리 하시겠습니까?`)) {
				console.log('helloworld')
				let result = await updateTrainingPass(Number(e.target.value), id, DriverNo)
				if (result.ResultCode == 200) {
					alert('처리가 완료 되었습니다.')
					setUpdateFlag(updateFlag + 1)
				}
				else {
					alert(result.ResultMessage)
				}
			}
		}

	}


	return (
		<>
			<Container fluid="md">
				<h2>교육 신청자 리스트</h2>
				<br />
				<Row>
					<Col xs={12}>
						<Calendar
							data={monthList}
							onSelectedDate={setSelectedDate}
							onMonthDate={setMonthDate}
						/>
					</Col>
				</Row>
				<br />
				<Row>
					<Col xs={12} className='text-center h3'>
						{selectedDate}
					</Col>
				</Row>
				<br />
				<Table striped bordered hover size="sm">
					<thead>
						<tr>
							<th>고유 Id</th>
							<th>파트너코드</th>
							<th>파트너이름</th>

							<th>교육타입</th>
							<th>신청교육장</th>
							<th>신청시간</th>

							<th>합격여부</th>
						</tr>
					</thead>
					<tbody>
						{TrainingReqList?.map((data) => (
							<tr>
								<td>{data.TrainingRequestId}</td>
								<td>{data.DriverNo}</td>
								<td>{data.UserName}</td>

								<td>{data.TrainingTypeStr}</td>
								<td>{data.TrainingVenueName}</td>
								<td>{data.TrainingVenueTime}</td>

								{!isNullOrUndefined(data.DriverNo) ? <>
									<td>
										{data.PassStatus ? <>합격일 : {moment(data.PassDt).format('yyyy-MM-DD')}</> : <Form.Select aria-label="Default select example" onChange={(e) => { handlePassUnpassUpdate(e, data.TrainingRequestId, data.DriverNo) }}>
											<option value='-1' selected={data.PassStatus === null} >--선택--</option>
											<option value='0' selected={data.PassStatus === false}>불합격</option>
											<option value='1' selected={data.PassStatus === true}>합격</option>
										</Form.Select>}
									</td>
								</> : <>
									<td>
										회원가입 필요
									</td>
								</>}

							</tr>
						))}


					</tbody>
				</Table>
				{/* <Button variant="primary" onClick={() => alert('구현중')}>일괄저장</Button>{' '} */}
			</Container>
		</>

	);
};

export default TrainingRequestListViewTeacher;

// 